// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DetallesComprobanteVenta__infoComprobanteContainer__IH_tS {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  flex-direction: column;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.DetallesComprobanteVenta__infoComprobanteRow__eG6JW {\r\n  display: flex;\r\n}\r\n\r\n.DetallesComprobanteVenta__titleDescriptionContainer__y1lth {\r\n  padding: 0;\r\n}\r\n\r\n.DetallesComprobanteVenta__title__1ODzG {\r\n  font-family: 'Roboto';\r\n  font-size: small;\r\n  font-weight: normal;\r\n  color: #474747ad;\r\n  margin-bottom: 5px !important;\r\n}\r\n\r\n.DetallesComprobanteVenta__description__35sDk {\r\n  box-sizing: border-box;\r\n  font-family: 'Roboto';\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n}\r\n\r\n.DetallesComprobanteVenta__descriptionSmall__3s6Ci {\r\n  box-sizing: border-box;\r\n  font-family: 'Roboto';\r\n  font-size: 12px;\r\n  font-weight: 500;\r\n}\r\n\r\n.DetallesComprobanteVenta__titleTotales__3WHi9 {\r\n  font-size: small;\r\n  font-weight: 500;\r\n  margin-bottom: 10px !important;\r\n  text-align: right;\r\n}\r\n\r\n.DetallesComprobanteVenta__montoTotales__2LvNo {\r\n  text-align: right;\r\n}\r\n\r\n.DetallesComprobanteVenta__Spinner__1Nzer {\r\n  text-align: center;\r\n  overflow-y: hidden;\r\n}\r\n\r\n.DetallesComprobanteVenta__contenedorTotales__yuhNs {\r\n  overflow-x: hidden;\r\n  padding: 16px;\r\n  margin-top: -8px;\r\n  margin-bottom: -8px;\r\n  text-align: right;\r\n}\r\n\r\n.DetallesComprobanteVenta__TitleCard__AV0YU {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n  background: rgb(251, 175, 64);\r\n  background: linear-gradient(90deg, rgba(251, 175, 64, 1) 0%, rgba(241, 91, 41, 1) 100%);\r\n  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#fbaf40\",endColorstr=\"#f15b29\",GradientType=1);\r\n  margin: -15px -15px 15px -15px;\r\n  padding-bottom: 10px;\r\n  padding-left: 15px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoComprobanteContainer": "DetallesComprobanteVenta__infoComprobanteContainer__IH_tS",
	"infoComprobanteRow": "DetallesComprobanteVenta__infoComprobanteRow__eG6JW",
	"titleDescriptionContainer": "DetallesComprobanteVenta__titleDescriptionContainer__y1lth",
	"title": "DetallesComprobanteVenta__title__1ODzG",
	"description": "DetallesComprobanteVenta__description__35sDk",
	"descriptionSmall": "DetallesComprobanteVenta__descriptionSmall__3s6Ci",
	"titleTotales": "DetallesComprobanteVenta__titleTotales__3WHi9",
	"montoTotales": "DetallesComprobanteVenta__montoTotales__2LvNo",
	"Spinner": "DetallesComprobanteVenta__Spinner__1Nzer",
	"contenedorTotales": "DetallesComprobanteVenta__contenedorTotales__yuhNs",
	"TitleCard": "DetallesComprobanteVenta__TitleCard__AV0YU"
};
export default ___CSS_LOADER_EXPORT___;
