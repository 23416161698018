// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationItems__NavigationItems__3tGJ9 {\r\n  margin: 0;\r\n  padding: 0;\r\n  list-style: none;\r\n  align-items: center;\r\n  height: 100%;\r\n  display: inline-block;\r\n  width: 100%;\r\n  background-color: RGB(81, 81, 81);\r\n  color: white;\r\n}\r\n\r\n.NavigationItems__NavigationItemIcon__30N6_ {\r\n  color: white !important;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavigationItems": "NavigationItems__NavigationItems__3tGJ9",
	"NavigationItemIcon": "NavigationItems__NavigationItemIcon__30N6_"
};
export default ___CSS_LOADER_EXPORT___;
