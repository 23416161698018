// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaDeudasDelMesPorSeleccion__TablaDeudasDelMesPorSeleccionContainer__2aOBK {\r\n  background-color: white;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  width: 100% !important;\r\n}\r\n\r\n.TablaDeudasDelMesPorSeleccion__TitleCard__3mOx6 {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  padding-right: 5px;\r\n  align-content: center;\r\n  padding-right: 0;\r\n}\r\n\r\n.TablaDeudasDelMesPorSeleccion__titleContent__3aEWI {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-right: 5px;\r\n}\r\n\r\n.TablaDeudasDelMesPorSeleccion__titleTexto__s1TyJ {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n@media (max-width: 750px) { \r\n  .TablaDeudasDelMesPorSeleccion__TablaDeudasDelMesPorSeleccionContainer__2aOBK {\r\n    max-width: 92vw !important;\r\n  }\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TablaDeudasDelMesPorSeleccionContainer": "TablaDeudasDelMesPorSeleccion__TablaDeudasDelMesPorSeleccionContainer__2aOBK",
	"TitleCard": "TablaDeudasDelMesPorSeleccion__TitleCard__3mOx6",
	"titleContent": "TablaDeudasDelMesPorSeleccion__titleContent__3aEWI",
	"titleTexto": "TablaDeudasDelMesPorSeleccion__titleTexto__s1TyJ"
};
export default ___CSS_LOADER_EXPORT___;
