// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Input__Input__2JD9x {\r\n  width: 90%;\r\n  /* box-sizing: border-box; */\r\n  /* border: 1px solid #ccc; */\r\n}\r\n\r\n.Input__InputElement__2h6u9 {\r\n  width: 100%;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Input": "Input__Input__2JD9x",
	"InputElement": "Input__InputElement__2h6u9"
};
export default ___CSS_LOADER_EXPORT___;
