// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TablaCubo__tablaCuboContainer__2pCgd {\r\n  border: 0px solid #ccc;\r\n  box-shadow: 0px 3px 6px #00000029;\r\n  box-sizing: border-box;\r\n  background-color: white;\r\n  border: 1px solid #d9d9d9;\r\n  border-radius: 3px;\r\n  width: 100%;\r\n}\r\n\r\n.TablaCubo__TitleCard__1Y_i1 {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-content: center;\r\n}\r\n\r\n.TablaCubo__iconSeleccionarMes__1d-VX:hover {\r\n  cursor: pointer;\r\n  background-color: #e4e4e4;\r\n}\r\n\r\n.TablaCubo__listaItems__1Gl-c {\r\n  padding: 0 !important;\r\n}\r\n\r\n.TablaCubo__listaItemsText__3ECap {\r\n  margin: 0 !important;\r\n}\r\n\r\n.TablaCubo__listaItemsTypography__1RX9m {\r\n  font-size: 15px !important;\r\n  text-align: right;\r\n}\r\n\r\n.TablaCubo__listaItemsCell1__1A5gI {\r\n  width: 20% !important;\r\n  padding: 6px 10px 6px 10px !important;\r\n}\r\n\r\n.TablaCubo__listaItemsCell2__1KHkP {\r\n  width: 20% !important;\r\n  padding: 6px 10px 6px 10px !important;\r\n}\r\n\r\n.TablaCubo__listaItemsCell3__3B34N {\r\n  width: 40% !important;\r\n  padding: 2px 5px 2px 5px !important;\r\n}\r\n\r\n.TablaCubo__listaItemsCell4__2Rw05 {\r\n  width: 20% !important;\r\n  padding: 6px 10px 6px 10px !important;\r\n}\r\n\r\n.TablaCubo__tablaCuboGastos__8xfLS::-webkit-scrollbar {\r\n  width: 8px;\r\n}\r\n\r\n/* Track */\r\n.TablaCubo__tablaCuboGastos__8xfLS::-webkit-scrollbar-track {\r\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\r\n  border-radius: 10px;\r\n}\r\n\r\n/* Handle */\r\n.TablaCubo__tablaCuboGastos__8xfLS::-webkit-scrollbar-thumb {\r\n  border-radius: 10px;\r\n  background: rgba(78, 78, 78, 0.8);\r\n  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n.TablaCubo__tablaCuboGastos__8xfLS::-webkit-scrollbar-thumb:window-inactive {\r\n  background: rgba(63, 63, 63, 0.4);\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablaCuboContainer": "TablaCubo__tablaCuboContainer__2pCgd",
	"TitleCard": "TablaCubo__TitleCard__1Y_i1",
	"iconSeleccionarMes": "TablaCubo__iconSeleccionarMes__1d-VX",
	"listaItems": "TablaCubo__listaItems__1Gl-c",
	"listaItemsText": "TablaCubo__listaItemsText__3ECap",
	"listaItemsTypography": "TablaCubo__listaItemsTypography__1RX9m",
	"listaItemsCell1": "TablaCubo__listaItemsCell1__1A5gI",
	"listaItemsCell2": "TablaCubo__listaItemsCell2__1KHkP",
	"listaItemsCell3": "TablaCubo__listaItemsCell3__3B34N",
	"listaItemsCell4": "TablaCubo__listaItemsCell4__2Rw05",
	"tablaCuboGastos": "TablaCubo__tablaCuboGastos__8xfLS"
};
export default ___CSS_LOADER_EXPORT___;
