const inDev = process.env.NODE_ENV === "development";
const inTest = false;

const devPortalUrl = 'http://localhost:3003';
const testPortalUrl = 'https://develop.login.jalisco365.com.ar';
const prodPortalUrl = 'https://login.jalisco365.com.ar';

const devDashboardUrlBase = 'https://localhost:44392/api/';
const testDashboardUrlBase = 'https://develop.dashboard.api.jalisco365.com.ar/api/';
const prodDashboardUrlBase = 'https://dashboard.api.jalisco365.com.ar/api/';

const devVendedoresUrlBase =  "https://localhost:44376/api/";
const testVendedoresUrlBase = "https://develop.vendedores.api.jalisco365.com.ar/api/";
const prodVendedoresUrlBase = "https://vendedores.api.jalisco365.com.ar/api/";

export const urlPortal = () => {
  return window.location.replace(inDev ? devPortalUrl : inTest ? testPortalUrl : prodPortalUrl);
};

export const urlPortalLogout = () => {
  return window.location.replace(
    inDev ? `${devPortalUrl}/logout` : inTest ? `${testPortalUrl}/logout` : `${prodPortalUrl}/logout`
  );
}

export const baseUrlVendedores = () => {
  return inDev ? devVendedoresUrlBase : inTest ? testVendedoresUrlBase : prodVendedoresUrlBase;
};

export const baseUrlDashboard = () => {
  return inDev ? devDashboardUrlBase : inTest ? testDashboardUrlBase : prodDashboardUrlBase;
};