// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DialogConfiguraciones__titleContent__3S4kB {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-right: 5px;\r\n}\r\n\r\n.DialogConfiguraciones__titleTexto__2mZ6B {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n.DialogConfiguraciones__subtitleTexto__3bL_s {\r\n  margin-top: -10px;\r\n  margin-left: 2px;\r\n  margin-bottom: 10px;\r\n  margin-right: 5px;\r\n}\r\n\r\n.DialogConfiguraciones__bodyDialog__3kCoL {\r\n  align-items: center;\r\n  display: flex;\r\n}\r\n\r\n.DialogConfiguraciones__TooltipInfo__9g0l6 {\r\n  z-index: 1500;\r\n  opacity: 1;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContent": "DialogConfiguraciones__titleContent__3S4kB",
	"titleTexto": "DialogConfiguraciones__titleTexto__2mZ6B",
	"subtitleTexto": "DialogConfiguraciones__subtitleTexto__3bL_s",
	"bodyDialog": "DialogConfiguraciones__bodyDialog__3kCoL",
	"TooltipInfo": "DialogConfiguraciones__TooltipInfo__9g0l6"
};
export default ___CSS_LOADER_EXPORT___;
