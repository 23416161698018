// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LabelGridTitleDescription__titleDescriptionContainer__2rVZy {\r\n  padding: 0;\r\n}\r\n\r\n.LabelGridTitleDescription__title__2Pnoc {\r\n  font-family: 'Roboto';\r\n  font-size: small;\r\n  font-weight: normal;\r\n  color: #474747ad;\r\n  margin-bottom: 5px !important;\r\n}\r\n\r\n.LabelGridTitleDescription__description__Ea1Kb {\r\n  box-sizing: border-box;\r\n  font-family: 'Roboto';\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n}\r\n\r\n.LabelGridTitleDescription__descriptionSmall__2cpbk {\r\n  box-sizing: border-box;\r\n  font-family: 'Roboto';\r\n  font-size: 12px;\r\n  font-weight: 500;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleDescriptionContainer": "LabelGridTitleDescription__titleDescriptionContainer__2rVZy",
	"title": "LabelGridTitleDescription__title__2Pnoc",
	"description": "LabelGridTitleDescription__description__Ea1Kb",
	"descriptionSmall": "LabelGridTitleDescription__descriptionSmall__2cpbk"
};
export default ___CSS_LOADER_EXPORT___;
