// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChequesValorEstado__ChequesValorEstadoContainer__2k0ij {\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.ChequesValorEstado__GuardarCambiosContainer__3f7_l {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    margin-top: -10px;\r\n    margin-bottom: -10px;\r\n}\r\n\r\n.ChequesValorEstado__EstadosContainer__3Npvq {\r\n  display: flex;\r\n  max-width: 50rem;\r\n  flex-flow: wrap;\r\n  grid-row-gap: 4px;\r\n  row-gap: 4px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChequesValorEstadoContainer": "ChequesValorEstado__ChequesValorEstadoContainer__2k0ij",
	"GuardarCambiosContainer": "ChequesValorEstado__GuardarCambiosContainer__3f7_l",
	"EstadosContainer": "ChequesValorEstado__EstadosContainer__3Npvq"
};
export default ___CSS_LOADER_EXPORT___;
